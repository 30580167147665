import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[ktStrictNumberOnlyDirective]'
})
export class StrictNumberOnlyDirectiveDirective {

  private strictNumber: string =  '^-?[0-9]*$';
  private decimalNumber: string = '^-?\\d*(\\.\\d{0,2})?$';
  private systemAttribNumericPattern = "^[0-9]*$";
  private systemAttribDecimalPattern = "^[0-9\\.]*$";

  private regex: RegExp = new RegExp(this.strictNumber);
  private specialKeys: Set<string> = new Set(['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete']);
  private decimalAllowed = false;


  @Input() set ktStrictNumberOnlyDirective(config: { isDecimalAllowed: boolean; systemAttrib: number }) {
    this.decimalAllowed = config?.isDecimalAllowed;
    const systemAttriblength = config?.systemAttrib ? Object.keys(config.systemAttrib).length:0;
    const isSystemAttrib = systemAttriblength > 0 ? true : false;
    if (config?.isDecimalAllowed) {
      this.regex = isSystemAttrib ? new RegExp (this.systemAttribDecimalPattern):new RegExp(this.decimalNumber);
    } else {
      this.regex = isSystemAttrib ? new RegExp (this.systemAttribNumericPattern):new RegExp(this.strictNumber);
    }
  }

  constructor(private elementRef: ElementRef) { }

  @HostListener('keydown', ['$event']) onkeyDown(event: KeyboardEvent) {
    if (this.specialKeys.has(event.key) || this.isModifierKey(event)) {
      return;
    }

    const inputValue: string = this.elementRef.nativeElement.value.concat(event.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
    return;
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      let regEx = new RegExp(this.strictNumber);
      if (this.decimalAllowed) {
        regEx = new RegExp(this.decimalNumber);
      }
      if (!regEx.test(clipboardData)) {
        event.preventDefault();
      }
    }
    return;
  }

  private isModifierKey(event: KeyboardEvent): boolean {
    return event.ctrlKey || event.altKey || event.metaKey;
  }
}
