import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-auth-dialog',
  templateUrl: './auth-dialog.component.html'
})
export class AuthDialogComponent {
  isEmail: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  // public eventEmitterService: EventEmitterService) { }

  onClose(): void {
      this.dialogRef.close();
  }

  onCloseWithSelection(isEmailSelected: boolean): void {
    this.isEmail = isEmailSelected;
    this.dialogRef.close({ isEmail: this.isEmail });
  }

  // Resend(){
  // this.eventEmitterService.onModalCloseResult('SUCCESS');
  //}

}
