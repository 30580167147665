// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { AuthNoticeService, AuthService, Login, User, UserLoaded } from '../../../../core/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { ProjectSelectionComponent } from '../project-selection/project-selection.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppState } from 'src/app/core/reducers';
import { Store } from '@ngrx/store';

@Component({
	selector: 'kt-authenticate-otp',
	templateUrl: './authenticate-otp.component.html',
	encapsulation: ViewEncapsulation.None
})
export class AuthenticateOtpComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	validateOtpForm: FormGroup;
	validOTP: boolean = false;
	loading = false;
	id: number;
	phone: string;
	user: User | null = null;
	userPhone: string;
	returnUrl: any;
	authFor: string;
	projectId: any;
	private menuObj: any = {};

	private unsubscribe: Subject<any>;

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private store: Store<AppState>,
		private dialog: MatDialog,
		public snackBar: MatSnackBar,
		private authService: AuthService,
		private modalService: NgbModal,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit() {
		this.user = this.authService.getUser();
		this.userPhone = this.user.phone;
		this.initRegistrationForm();
	}

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	initRegistrationForm() {
		this.validateOtpForm = this.fb.group({
			otp: ['', [Validators.required, Validators.pattern('^\\d{6}$')]],
		});

		this.registerForm = this.fb.group({
			username: [this.userPhone, Validators.compose([
				Validators.required,
				Validators.pattern('^\\d{10}$')
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(50)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(50)
			])
			],
			//	agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submitForResetPwd() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		const _user: User = new User();
		_user.clear();
		_user.phone = controls.username.value;
		_user.email = this.user.email;
		_user.username = this.user.email;
		_user.password = controls.password.value;

		console.log('UserName : ' + _user.username + ', Password : ' + _user.password);
		this.resetPassword(_user);

	}

	submit() {
		const controls = this.validateOtpForm.controls;
		console.log('OTP this.user.phone :' + this.user.phone);
		if (this.validateOtpForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
		const otp = controls.otp.value.trim();
		this.authService.validateOtp(otp, this.user.id).pipe(
			tap(response => {
				if (response.statusCode == 200) {
					this.validOTP = true;
					this.authNoticeService.setNotice(null);
				} else {
					this.authNoticeService
						.setNotice(this.translate
							.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.MOBILE') }), 'danger');
				}
			}, error => {
				this.snackBar.open('The OTP entered is invalid or expired. Please try again.', '', {
					duration: 3000,
					panelClass: ['red-snackbar'],
				});
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();

	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.validateOtpForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
	/**
	 * 
	 * @param user 
	 * @param mcode 
	 * @param resendFlag 
	 */

	requestOtp() {
		this.loading = true;

		this.authService.requestOtp(this.user.phone).pipe(
			tap(response => {
				if (response.statusCode == 200) {
					this.openDialog(this.user, 5, 0);
					this.authNoticeService.setNotice(null);
					this.router.navigateByUrl('/auth/resetpassword-token/');
				} else {
					this.authNoticeService
						.setNotice(this.translate
							.instant('AUTH.VALIDATION.NOT_FOUND', { name: this.translate.instant('AUTH.INPUT.MOBILE') }), 'danger');
				}
			}, error => {
				this.snackBar.open('Error occurred while resetting password. Please try again.', '', {
					duration: 3000,
					panelClass: ['red-snackbar']
				});
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();
	}

	openDialog(user: any, mcode: number, resendFlag: number): void {
		let dialogRef = this.dialog.open(AuthDialogComponent, {
			width: '650px',
			data: { name: user.firstName, email: user.email, code: mcode, resendFlag: resendFlag, phone: user.phone }
		});

		dialogRef.afterClosed().subscribe(() => {
			this.router.navigateByUrl('/auth/authenticate-otp');
		});
	}

	selectProject(user: User) {
		const ref = this.modalService.open(ProjectSelectionComponent,
			{
				centered: false,
				size: 'md',
				backdrop: 'static'
			});
		ref.componentInstance.userDetail = user;
	}

	/**
	 * 
	 * @param user 
	 */
	fetchUserMenu(user: User) {
		this.projectId = user.defaultProjectId;

		return this.authService.getUserMenu(this.projectId).subscribe(
			async data => {
				const userMenu = this.finalizeUserMenu(data['responseData']);
				this.createPageHeading(userMenu);
				const finalMenu: any = {
					header: {
						self: {},
						items: userMenu
					}
				}

				this.store.dispatch(new Login({ authToken: user.token })); //user.accessToken
				localStorage.setItem('currentUser', JSON.stringify(user));
				localStorage.setItem('userProject', JSON.stringify(user.userProject));
				localStorage.setItem('userMenu', JSON.stringify(finalMenu));
				localStorage.setItem('selectedProject', this.projectId);
				console.log("Past local storage : " + localStorage.getItem('selectedProject'));

				if (this.authFor === 'Create-Password') {
					//this.router.navigateByUrl('/settings-assistant');
					window.location.href = window.location.origin + "/settings-assistant";
				} else {
					this.router.navigateByUrl(this.returnUrl); // Main page
				}
			}
		)
	}

	finalizeUserMenu(menuData: any) {
		for (let res of menuData) {
			if (res.root == true) {
				res.alignment = 'left';
				res.toggle = 'click';
			}
		}
		return menuData;
	}

	/**
	 * 
	 * @param userMenu 
	 */
	createPageHeading(userMenu: any) {
		/* First Level */
		for (let res of userMenu) {
			if (res.page && res.page != '#') {
				this.generatePageHeading(res);
			}
			/* Second Level */
			if (res.submenu) {
				for (let res1 of res.submenu) {
					if (res1.page && res1.page != '#') {
						this.generatePageHeading(res1);
					}
					/* Third Level */
					if (res1.submenu) {
						for (let res2 of res1.submenu) {
							if (res2.page && res2.page != '#') {
								this.generatePageHeading(res2);
							}
							/* Forth Level */
							if (res2.submenu) {
								for (let res3 of res2.submenu) {
									if (res3.page && res3.page != '#') {
										this.generatePageHeading(res3);
									}
								}
							}
						}
					}
				}
			}
		}

		localStorage.setItem('pageTitle', JSON.stringify(this.menuObj));
	}

	/**
	 * 
	 * @param menu 
	 */
	generatePageHeading(menu: any) {
		let jsonVal = {};
		let pageName = menu.page.replace(/\//g, '');

		jsonVal = {
			"page": {
				"title": menu.title,
				"desc": '',
				"singularName": menu.singularName,
				"pruralName": menu.pruralName
			}
		};
		this.menuObj[pageName] = jsonVal;
	}

	resetPassword(_user: User) {
		this.authService.resetPassword(_user).pipe(
			tap(user => {
				if (user) {
					if (user.statusCode == 200) {
						const authData = {
							username: this.user.email,
							password: _user.password
						};
						this.initiateLogin(authData);
					} else {
						this.authNoticeService.setNotice(user.message, 'danger');
						this.router.navigateByUrl('/auth/login');
					}
				} else {
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					this.router.navigateByUrl('/auth/login');
				}
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		).subscribe();
	}

	initiateLogin(authData) {
		this.authService
			.login(this.user.email, authData.password)
			.pipe(
				tap(res => {
					if (res) {
						var userObj = new User;
						userObj = res.user;
						userObj.token = res.token;
						this.store.dispatch(new UserLoaded({ user: userObj }));

						console.log("User Project Length : " + userObj.userProject.length);
						if (userObj.userProject.length > 1 && (userObj.defaultProjectId == null || userObj.defaultProjectId == 0)) {
							this.selectProject(userObj);
						} else {
							if (+userObj.defaultProjectId > 0) {
								this.fetchUserMenu(userObj);
							} else {
								userObj.defaultProjectId = res.user.userProject[0].project.projectId;
								this.fetchUserMenu(userObj);
							}
						}
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}

				}),

				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe(
				user => {
					console.log('Login Sucessfulll');
					if (this.authFor === 'Create-Password') {
						this.router.navigateByUrl('/settings-assistant');
					} else {
						this.router.navigateByUrl(this.returnUrl); // Main page
					}
				},
				error => {
					this.loading = false;
					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');

				}
			);
	}
}
