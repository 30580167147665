// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, User, UserLoaded } from '../../../../core/auth';

//material
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ProjectSelectionComponent } from '../project-selection/project-selection.component';
import { EventEmitterService } from '../../shared/services/event-emitter.service';
import { environment } from '../../../../../environments/environment';
import { ConfirmLoginComponent } from './subs/confirm-login/confirm-login.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	EMAIL: '',
	PASSWORD: ''
};

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	projectId: any;

	private unsubscribe: Subject<any>;

	private returnUrl: any;
	private menuObj:any = {};
	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private dialog: MatDialog
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		console.log("environment name:",environment.name);
		/*if (this.eventEmitterService.subsVar==undefined) {
			this.eventEmitterService.subsVar = this.eventEmitterService.
			invokeFirstComponentFunction.subscribe((data:any) => {
			  this.fetchUserMenu(data);
			});
		  }*/

		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		// demo message to show
		/*if (!this.authNoticeService.onNoticeChanged$.getValue()) {
			const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
			this.authNoticeService.setNotice(initialNotice, 'info');
		}*/

		this.loginForm = this.fb.group({
			email: [DEMO_PARAMS.EMAIL, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(1),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: [DEMO_PARAMS.PASSWORD, Validators.compose([
				Validators.required,
				Validators.minLength(1),
				Validators.maxLength(100)
			])
			]
		});
	}

	openForgotPasswordDialog(mcode: number, resendFlag: number): void {
		let dialogRef = this.dialog.open(AuthDialogComponent, {
		  width: '650px',
		  data: {code: mcode, resendFlag: resendFlag }
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (result.isEmail) {
				  this.router.navigateByUrl('/auth/forgot-password?type=email');
				} else {
				  this.router.navigateByUrl('/auth/forgot-password?type=mobile');
				}
			  }
			});
	  }
	  
	/**
	 * Form Submit
	 */
	submit() {

		this.loading = true;
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		const authData = {
			username: controls.email.value.trim(),
			password: controls.password.value
		};

		this.auth
			.login(authData.username, authData.password)
			.pipe(
				tap(res => {
					this.handleApiResponse(res); 
				}),

				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
			.subscribe(
				user => {
					console.log('Login Sucessfulll');
				//	this.router.navigateByUrl(this.returnUrl); // Main page
				},
				error => {
					this.loading = false;
					if(error.status === 409){
						this.confirmLogin();
					} else {
						this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
					}

				}
			);
	}

	private handleApiResponse(res: any) {
		if (res) {
			var userObj = new User;
			userObj = res.user;
			userObj.token = res.token;
			if (res.appServerUrl == undefined || res.appServerUrl == '')
				environment.baseUrl = environment.loginUrl;

			else
				environment.baseUrl = res.appServerUrl;
			localStorage.setItem('baseUrl', environment.baseUrl);
			this.store.dispatch(new UserLoaded({ user: userObj }));
			this.store.dispatch(new Login({ authToken: userObj.token })); //user.accessToken
			this.selectProject(userObj);
		} else {
			this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
		}
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	/**
	 * 
	 * @param loginResult 
	 */
	selectProject(user: User){
		const ref=this.modalService.open(ProjectSelectionComponent,
			{
			  centered: false,
			  size : 'md',
			  backdrop : 'static'
			});
		ref.componentInstance.userDetail=user;
		ref.componentInstance.selectionFlag='Login';
	}

	/**
	 * 
	 * @param user 
	 */
	/*fetchUserMenu(user: User){
		console.log(user);

		this.projectId = user.defaultProjectId;
		console.log(this.projectId);

		return this.auth.getUserMenu(this.projectId).subscribe(
			data=>{
					const userMenu = this.finalizeUserMenu(data['responseData']);

					this.createPageHeading(userMenu);

					const finalMenu: any = {
										header: {
											self: {},
											items: userMenu
										}
									}

					this.store.dispatch(new Login({authToken: user.token})); //user.accessToken
					localStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('userProject', JSON.stringify(user.userProject));
					localStorage.setItem('userMenu', JSON.stringify(finalMenu));
					console.log("Before local storage : "+localStorage.getItem('selectedProject'));
					localStorage.setItem('selectedProject', this.projectId);
					console.log("Past local storage : "+localStorage.getItem('selectedProject'));

					this.router.navigateByUrl(this.returnUrl); // Main page
			}
		  )
	}*/

	/*finalizeUserMenu(menuData: any){
		for(let res of menuData) {
			if(res.root == true) {
				res.alignment = 'left';
				res.toggle = 'click';
			}
		}
		return menuData;
	}*/

	/**
	 * 
	 * @param userMenu 
	 */
	/*createPageHeading(userMenu: any)
	{
		// First Level 
		for(let res of userMenu){
			if(res.page && res.page != '#'){
			  this.generatePageHeading(res);
			}
			// Second Level 
			if(res.submenu){
			  for(let res1 of res.submenu){
				if(res1.page && res1.page != '#'){
				  this.generatePageHeading(res1);
				}
				// Third Level 
				if(res1.submenu){
				  for(let res2 of res1.submenu){
					if(res2.page && res2.page != '#'){
					  this.generatePageHeading(res2);
					}
					// Forth Level 
					if(res2.submenu){
					  for(let res3 of res2.submenu){
						if(res3.page && res3.page != '#'){
						  this.generatePageHeading(res3);
						}
					  }
					}
				  }
				}
			  }
			}
		  }

		  localStorage.setItem('pageTitle', JSON.stringify(this.menuObj));
	}*/

	/**
	 * 
	 * @param menu 
	 */
	/*generatePageHeading(menu:any)
	{
		let jsonVal = {};
    	let pageName = menu.page.replace(/\//g, '');

		jsonVal = {
					"page" : {
						"title": menu.title,
						"desc": '',
						"singularName":menu.singularName,
						"pruralName": menu.pruralName
					}
				};
			this.menuObj[pageName] = jsonVal;
	}*/

	confirmLogin() {
		const ref = this.modalService.open(ConfirmLoginComponent,
			{
				centered: true,
				size: 'md',
				backdrop: 'static'
			});

		ref.result.then((result) => {
			if (result === true) {
				this.loading = true;
				this.cdr.markForCheck();
				const controls = this.loginForm.controls;
				/** check form */
				if (this.loginForm.invalid) {
					Object.keys(controls).forEach(controlName =>
						controls[controlName].markAsTouched()
					);
					return;
				}

				const authData = {
					username: controls.email.value.trim(),
					password: controls.password.value
				};

				this.auth
					.confirmlogin(authData.username, authData.password)
					.pipe(
						tap(res => {
							this.handleApiResponse(res);
						}),

						takeUntil(this.unsubscribe),
						finalize(() => {
							this.loading = false;
							this.cdr.markForCheck();
						})
					)
					.subscribe(
						user => {
							console.log('Confirm Login Sucessfulll');
							//	this.router.navigateByUrl(this.returnUrl); // Main page
						},
						error => {
							this.loading = false;
							this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
						}
					);
			}
		});
	}

}