
<div *ngIf="ShowModal" class="example-preview">
    <div>
            <div class="modal-header">
                <h4 class="modal-title">{{modalHeader}}</h4>
            
                
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="addCompaniesForm" autocomplete="off" >
                    <div class="row">
                        <div class="col-md-4">
                          <label class="col-form-label form-padding-top-25">Serial No.</label>
                        </div>
                        <div class="col-md-8">
                          <mat-form-field class="mat-form-field-fluid">
                            <input matInput formControlName="serialNo" ktAutoTrimSpaces>
                            <mat-icon matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon> 
                          </mat-form-field>                                            
                        </div>
                      </div>    
                        <div id="div_{{customAttr.combinedAttributeName}}_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentAttributeId !==0 && customAttr.isEdit}" class="form-group row" *ngFor="let customAttr of customAttributesList">

                            <div class="col-md-4" *ngIf="!customAttr.isHidden">
                                <label class="col-form-label form-padding-top-25" [ngClass]="{'header-text': customAttr.attributeType.id == 10, 'normal-text': customAttr.attributeType.id == 11}">{{customAttr.attributeName}}
                                    <span class="mandatory-marker">{{customAttr.mandatoryFlag === 1 ? '*' : ''}}</span>
                                </label>
                            </div>
                                                                        
                            <!-- first div -->
                
                            <div class="col-md-8" *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21); else systemAttrbitues ">
                                <mat-form-field appearance="standard" class="input-100"  *ngIf="!customAttr.isHidden">
                                    
                                    <mat-select [formControlName]="customAttr.combinedAttributeName" (selectionChange)="invokeChildMethod($event.value,customAttr,'')">
                                        
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="singleDropdownFilter" ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                            {{values.lookupValue}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon  *ngIf="isFieldDisable||customAttr.systemAttribute.id==66||(attributeIdVisiblityMap.has(customAttr.id)
                                    
                                    && attributeIdVisiblityMap.get(customAttr.id)==1) || !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>    
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     

                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>   
                                                    
                                
                                </mat-form-field>                    
                            </div>   
                                    
                            <!-- product form -->
                            <div id="select_{{customAttr.id}}" class="col-md-12" *ngIf="customAttr.attributeType.id==13 && customAttr.parentFormId !==null && !customAttr.isHidden">
                        
                                <div *ngIf="moduleType > 100  && customAttr.parentFormId.id !==null && customAttr.parentFormId.id !==0" style="font-size:10px; padding:0 0 0 8px;">
                                    <div >
                                        <div class="form-group row" style="overflow-x: auto;" [formArrayName]="customAttr.combinedAttributeName" *ngIf="productFormFields[customAttr.combinedAttributeName]">
                                            <div class="row">
                                                <td>                                            
                                                    <tr>
                                                        <table class="style">
                                                            <tr>
                                                                <th *ngFor="let field of productFormFields[customAttr.combinedAttributeName]?.fields">{{field.attributeName}}</th>
                                                                <th *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!=1">Action</th>
                                                            </tr>
                                                            <tr *ngFor="let item of getControls(customAttr.combinedAttributeName); let i=index; last as isLast" [formGroupName]="i">
                                                                    <td *ngFor="let field of productFormFields[customAttr.combinedAttributeName]?.fields">
                                                                        <mat-form-field  class="inputbox" *ngIf="field.attributeType.id!=13 && (field.attributeType.id==7 || field.attributeType.id==14)"> 
                                                                            <input [readonly]='field.systemAttribute?.id == 50 || field.systemAttribute?.id == 52 || field.attributeType.id==14' (input)="calculateSummeryFieldsIfMatches(customAttr.combinedAttributeName, field.combinedAttributeName)" matInput formControlName="{{field.combinedAttributeName}}" [required]="isMandatory(customAttr.mandatoryFlag)" [ktStrictNumberOnlyDirective]="{ isDecimalAllowed: field.decimalFlag, systemAttrib: field.systemAttribute }"
                                                                             (change)="productFormFieldChange(customAttr.combinedAttributeName,i,field,$event.target.value)">  
                                                                        </mat-form-field>
                                                                        <mat-form-field class="inputSelect"  *ngIf="field.attributeType.id==13 && field.systemAttribute && field.systemAttribute.id==48">
                                                                            <input *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView===1" matInput formControlName="{{productFormFields[customAttr.combinedAttributeName].productFieldNameInput}}">  
                                                                            <mat-select  *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!==1" formControlName="{{field.combinedAttributeName}}" [required]="isMandatory(customAttr.mandatoryFlag)" (selectionChange)="updateItems($event.value,i,customAttr.combinedAttributeName,customAttr.parentFormId)">
                                                                                <mat-option *ngFor="let sku of this.productFormIdWithProductIdNameMap.get(customAttr.parentFormId)" [value]="sku.productId" [disabled]="!sku.isSelectionAllowed">
                                                                                    {{sku.productName}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                         </mat-form-field>
                                                                         <!-- form here (Date)  -->
                                                                         <mat-form-field class="example-full-width"  *ngIf="field.attributeType.id==3">
                                                                            <input matInput readonly [formControlName]="field.combinedAttributeName"  [matDatepicker]="picker">
                                                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                            <mat-datepicker #picker></mat-datepicker>
                                                                        </mat-form-field>
        
                                                                        <!-- from here (Text-single-line) -->
                                                                        <mat-form-field class="mat-form-field-fluid" *ngIf="field.attributeType.id==6">
                                                                            <input matInput #message matInput placeholder="" [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces>
                                                                        </mat-form-field> 
                                                                        
                                                                        <!-- from here (Text-multi-line) -->
                                                                        <mat-form-field appearance="standard" class="input-100"  *ngIf="field.attributeType.id==8">
                                                                            <textarea  maxlength="{{field.maxCharacters}}" minlength="{{field.minCharacters}}" #message matInput placeholder="" 
                                                                            [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces></textarea>
                                                                        </mat-form-field>
        
                                                                        <!-- from here (Email) -->
                                                                        <mat-form-field class="mat-form-field-fluid" *ngIf="field.attributeType.id==9">
                                                                            <input maxlength="{{field.maxCharacters}}" minlength="{{field.minCharacters}}" #message matInput placeholder="" [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces>
                                                                        </mat-form-field>
        
                                                                         <!-- from here (Image) -->    
                                                                         <div *ngIf="field.attributeType.id==4" style="display: flex; flex-direction: row; max-width: 80px; overflow-x:auto; overflow-y: hidden; height: 80px;">
                                                                            <input id="{{customAttr.combinedAttributeName + field.combinedAttributeName + i}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                                                            (change)="processFile($event,customAttr.combinedAttributeName,field.attributeType.id,field.maxImagesAllowed, field.combinedAttributeName,i)">
                                                                            
                                                                            <span *ngIf="fileArray[customAttr.combinedAttributeName+field.combinedAttributeName+i].showUploadIcon" style="display: flex; flex-direction: row;"> 
                                                                                <div class="artist-collection-photo" *ngIf="(!isFieldDisable && !productFormDisabled[customAttr.combinedAttributeName])" style="width: 40px;">
                                                                                    <img width="40" height="50" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName, field.combinedAttributeName+i)">	
                                                                                    <div *ngIf="field.maxImagesAllowed && fileArray[customAttr.combinedAttributeName+field.combinedAttributeName + i].uploaded == 0" class="img-index">
                                                                                        {{"0"}}/{{field.maxImagesAllowed}}
                                                                                    </div>
                                                                                </div>
                                                                            </span>
         
                                                                            <span *ngIf="fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]" style="display: flex; flex-direction: row;">
                                                                                <span *ngFor="let file of fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]?.files; index as ix">
                                                
                                                                                    <div class="artist-collection-photo" style="width: 35px; margin: 4px;">
                                                                                        <div *ngIf="!isFieldDisable && !productFormDisabled[customAttr.combinedAttributeName]" class="remove-img-btn"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, ix, field.combinedAttributeName,i,field)">
                                                                                            <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                                                        </button></div>
                                                                                        <img width="40" height="50" [src]= "baseUrl + file" alt="image.jpg" class="img-thumbnail" (click)="showImageModalOnly(file)">
                                                                                        <div *ngIf="field.maxImagesAllowed" class="img-index"> {{ix+1}}/{{field.maxImagesAllowed}} </div>
                                                                                        <div *ngIf="!field.maxImagesAllowed" class="img-index text-center ml-0"> {{ix+1}} </div>
                                                                                    </div>
                                                
                                                                                </span>
                                                                            </span>
                                                                            <span>
                                                                                <div *ngIf=" (isFieldDisable||productFormDisabled[customAttr.combinedAttributeName]) && fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i].uploaded == 0" class="pt-6">No file uploaded</div>
                                                                            </span>
                                                                            <mat-error *ngIf="isFormArrayControlHasError(customAttr.combinedAttributeName,field.combinedAttributeName,i,'required')">{{field.attributeName}} can not be blank</mat-error>
                                                                         </div>   
        
                                                                          <!-- From here (single select)-->  
                            
                                                                        <div *ngIf="field.attributeType.id==1; else systemAttrbitues ">
                                                                            <mat-form-field  appearance="standard" class="input-100">
                                                                                <mat-select  [formControlName]="field.combinedAttributeName">
                                                                    
                                                                                    <mat-option *ngFor="let values of field.lookupValues" [value]="values.id">
                                                                                        {{values.lookupValue}}
                                                                                    </mat-option>
                                                                                    
                                                                                </mat-select>
                                                                            </mat-form-field>                    
                                                                        </div>                   
        
                                                                        <!-- From here (multi select)-->
                                                                            
                                                                        <div *ngIf="field.attributeType.id==2" >
                                                                            <mat-form-field class="mat-form-field-fluid">
                                                                                <mat-select #select3 [formControlName]="field.combinedAttributeName" multiple>
                                                                                    <div class="select-all">
                                                                                        <mat-checkbox [(ngModel)]="field.allSelected"
                                                                                                [ngModelOptions]="{standalone: true}"
                                                                                                (change)="toggleAllSelection(select3,field)">Select All
                                                                                        </mat-checkbox>
                                                                                      </div>
                                                                                    <mat-option (click)="optionClick(select3,field)" *ngFor="let values of field.lookupValues" [value]="values.id">
                                                                                        {{values.lookupValue}}
                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                        </div>   
                                                                    </td>
                                                                    
                                                                    <td *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!=1" style="min-width:90px;"> 
                                                                        <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="isLast"
                                                                            [disabled]="productFormDisabled[customAttr.combinedAttributeName]">
                                                                            <i class="fas fa-plus" (click)="addTable(customAttr.combinedAttributeName)"></i>
                                                                        </button>
                                                    
                                                                        <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary"
                                                                            [disabled]="productFormDisabled[customAttr.combinedAttributeName]">
                                                                            <i class="fa fa-minus" (click)="deleteRow(customAttr.combinedAttributeName, i)"></i>
                                                                        </button>
                                                                       
                                                                    </td>
                                                                    
                                                          </tr>
                                                          <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isAllowDiscount">
                                                              <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                                <div class="col-md-12 text-right" >
                                                                    <label class="col-form-label form-padding-top-15">Sub-total</label>
                                                                </div>
                                                              </td>
                                                              <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                                 <input matInput ktTwoDigitDecimaNumberDirective 
                                                                    [(ngModel)]="productFormFields[customAttr.combinedAttributeName].subTotal" 
                                                                    [ngModelOptions]="{standalone: true}" readonly> 
                                                              </td>
                                                          </tr>
                                                          <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isDiscountValue">
                                                            <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                              <div class="col-md-12 text-right" >
                                                                  <label class="col-form-label form-padding-top-15">Discount Amount</label>
                                                              </div>
                                                            </td>
                                                            <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                                <input matInput ktTwoDigitDecimaNumberDirective 
                                                                    [(ngModel)]="productFormFields[customAttr.combinedAttributeName].discountValue" 
                                                                    [ngModelOptions]="{standalone: true}" 
                                                                    [disabled]="isFieldDisable||productFormDisabled[customAttr.combinedAttributeName]"
                                                                    (input)="discountValueChanged(customAttr.combinedAttributeName)"> 
                                                            </td>
                                                         </tr>
                                                         <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isDiscountPercent">
                                                            <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                              <div class="col-md-12 text-right" >
                                                                  <label class="col-form-label form-padding-top-15">Discount %</label>
                                                              </div>
                                                            </td>
                                                            <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                                <input matInput ktTwoDigitDecimaNumberDirective  
                                                                    [(ngModel)]="productFormFields[customAttr.combinedAttributeName].discountPercent" 
                                                                    [ngModelOptions]="{standalone: true}" 
                                                                    [disabled]="isFieldDisable||productFormDisabled[customAttr.combinedAttributeName]"
                                                                    (input)="discountPercentChanged(customAttr.combinedAttributeName)">
                                                            </td>
                                                         </tr>
                                                         <tr>
                                                            <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                              <div class="col-md-12 text-right" >
                                                                  <label class="col-form-label form-padding-top-15">Total</label>
                                                              </div>
                                                            </td>
                                                            <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                                 <input matInput ktTwoDigitDecimaNumberDirective 
                                                                    [(ngModel)]="productFormFields[customAttr.combinedAttributeName].total" 
                                                                    [ngModelOptions]="{standalone: true}" readonly> 
                                                              </td>
                                                         </tr>
                                                        
                                                        </table>
                                                     </tr>
                                                     <tr style="height: 20px;"></tr>
                                                </td>
                                            </div>  
                                           
                                        </div>                                   
                                    </div>  
                                    <mat-error *ngIf="addProductError">Please select product.</mat-error>
                                  
                                </div>
        
                                <div class="col-md-12" *ngIf="viewMode === 'AppPreview' && moduleType > 100 && customAttr.systemAttribute.id==28" style="font-size:10px; padding:0 0 0 8px;">
                                    <div >
                                        <div class="form-group row" formArrayName="skuItems">
                                            <div class="row">
                                                <td>                                            
                                                    <tr>
                                                        <table class="style">
                                                            <tr>
                                                                <th  style=" background-color: #F3F6F9;">Product</th>
                                                                <th  style=" background-color: #F3F6F9;">Units</th>
                                                                <th  style=" background-color: #F3F6F9;">Subtotal</th>
                                                                <th  style=" background-color: #F3F6F9;">Actions</th>
                                                                
                                                            </tr>
                                                            <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                               
                                                                    <td class="inputbox4" style=" width: 75px;">
                                                                      
                                                                    </td>
                                                                    <td class="inputbox3" style=" width: 25px;">
                                                                       
                                                                    </td>
                                                                    <td class="inputbox2" style="width: 50px;">
                                                                      
                                                                    </td>
                    
                                                                    <td class="inputbox6" style="width: 60px;">
                                                                     
                                                                    </td>  
                                                          </tr>
                                                        </table>
                                                    </tr>
                                                          <tr> 
                                                            <table class="style">
                                                                <tr>
                                                                <td class="inputbox5" style=" width: 103px;">
                                                                        <span>Total</span>
                                                                </td>
                                                                
                                                                <td class="inputbox2" style=" width: 50px;">
                                                                    <span>0.00</span>
                                                                </td>
                                                                <td style="width:60px; padding-left: 10px; background-color: #F3F6F9;"> 
                                                                    <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                        <i class="fas fa-plus" ></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                          </table>
        
                                                          </tr>
                                                     <tr style="height: 20px;"></tr>
                                                </td>
                                            </div>  
                                           
                                        </div>                                   
                                    </div>  
                                    <mat-error *ngIf="addProductError">Please select product.</mat-error>
                                  
                                </div>
        
                            </div>

                            <!-- second div -->
                                
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==2 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) && (customAttr.systemAttribute['id'] !==28); else systemAttrbituesM " >
                                <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                    <mat-select [formControlName]="customAttr.combinedAttributeName" multiple (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr.id,'') : ''">
                                        
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="multiDropdownFilter" ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                            {{values.lookupValue}}
                                        </mat-option>
                                    </mat-select>
                                    
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1) || !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon> 
                                         
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                </mat-form-field>
                                                
                            </div>

                                <!-- third div -->
                            
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==6 ||customAttr.attributeType.id==20">
                                <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                    <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" matInput #message placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                    <mat-error *ngIf="customAttr.attributeType.id==6 && isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                    <mat-icon  *ngIf="!customAttr.isEdit||(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>   
                                    <mat-icon *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                                    <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                                </mat-form-field>                                            
                            </div>
            
                            <!-- fourth div -->
                        
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==5 && !customAttr.isHidden">
                                <mat-radio-group [formControlName]="customAttr.combinedAttributeName" fxLayout="column" fxLayoutGap=".25rem">
                                    <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                                        {{values.lookupValue}}
                                    </mat-radio-button>
                                                                                                                                                                                                                
                                </mat-radio-group>  
                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                && attributeIdVisiblityMap.get(customAttr.id)==1) || !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                <mat-hint>{{customAttr.instructions}}</mat-hint>       
                                                                            
                                <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                            </div>
            
                            <!-- fifth div -->
                        
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                                <div *ngIf="customAttr.numericValidationType==1">
                                <div *ngIf="customAttr.maxDigits!=null">
                                <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                    <input [maxlength]="(customAttr.decimalFlag ? customAttr.maxDigits + 3 : customAttr.maxDigits) + (message.value.startsWith('-') ? 1 : 0)"
                                    #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName"
                                    [ktStrictNumberOnlyDirective]="{ isDecimalAllowed: customAttr.decimalFlag, systemAttrib: customAttr.systemAttribute }">
                                    <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint> 
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                    <mat-icon  *ngIf="!customAttr.isEdit ||(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1) " matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>   
                                    <mat-icon  *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint align="end">
                                        {{(customAttr.decimalFlag ? getDecimalFieldLength(message.value) : message.value.length) - (message.value.startsWith('-') ? 1 : 0)}}
                                        / {{customAttr.maxDigits}}
                                    </mat-hint>
                                </mat-form-field> 
                                </div> 
                                <div *ngIf="customAttr.maxDigits==null">
                                    <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                        <input  #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName"
                                        [ktStrictNumberOnlyDirective]="{ isDecimalAllowed: customAttr.decimalFlag, systemAttrib: customAttr.systemAttribute }">
                                        <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>  
                                        <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                        <mat-icon  *ngIf="!customAttr.isEdit||(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>   
                                        <mat-icon  *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                        <mat-hint  align="end">{{message.value.length}} </mat-hint>
                                    </mat-form-field> 
                                    </div> 
                                </div>
                                <div *ngIf="customAttr.numericValidationType==2">
                                    <div *ngIf="customAttr.maxNumber!=null">
                                        <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                            <input [maxlength]="(customAttr.maxNumber.toString().length + (message2.value.startsWith('-') ? 1 : 0))"
                                            #message2 matInput
                                             placeholder="" [formControlName]="customAttr.combinedAttributeName"
                                             [ktStrictNumberOnlyDirective]="{ isDecimalAllowed: customAttr.decimalFlag, systemAttrib: customAttr.systemAttribute }">
                                            <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>
                                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                            <mat-icon  *ngIf="!customAttr.isEdit||(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>      
                                            
                                        <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                            <mat-icon  *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                            <mat-hint align="end">
                                                {{message2.value.length}} / {{customAttr.maxNumber.toString().length + (message2.value.startsWith('-') ? 1 : 0)}}
                                            </mat-hint>
                                        </mat-form-field>  
                                    </div>
                                    <div *ngIf="customAttr.maxNumber == null">
                                        <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                            <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName"
                                            [ktStrictNumberOnlyDirective]="{ isDecimalAllowed: customAttr.decimalFlag, systemAttrib: customAttr.systemAttribute }">

                                            <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>   
                                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                            <mat-icon  *ngIf="!customAttr.isEdit||(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>    
                        
                                            
                                        <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                            <mat-icon  *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                            <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                        </mat-form-field>  
                                    </div>

                                </div>
                                <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                                    <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                        <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName"
                                        [ktStrictNumberOnlyDirective]="{ isDecimalAllowed: customAttr.decimalFlag, systemAttrib: customAttr.systemAttribute }">
                                        <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                                        
                                    <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                        <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                        <mat-icon *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                        <mat-icon  *ngIf="!customAttr.isEdit||(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                        <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                    </mat-form-field>  
                                </div>
                                
                                
                            </div>

                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==8">
                                <mat-form-field appearance="standard" class="input-100"  *ngIf="!customAttr.isHidden">
                                    <textarea maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" matInput #message placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces></textarea>
                                    
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                    <mat-icon  *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                                    <mat-icon  *ngIf="!customAttr.isEdit || (attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                    <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                                </mat-form-field>
                                                                            
                            </div>

                                <!-- six div -->
                            
                        <!-- <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                                <mat-form-field class="example-full-width">
                                    <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    
                                    <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                                    <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                </mat-form-field>
                                                    
                            </div>  -->
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMaxFlag && !DisplayDateMinFlag && !DisplayDateFlag">
                                <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                                    <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)

                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                </mat-form-field>
                                                    
                            </div>
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMinFlag && !DisplayDateMaxFlag && !DisplayDateFlag">
                                <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                                    <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate"  [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon> 
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                </mat-form-field>
                                                    
                            </div>
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag ">
                                <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                                    <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate"  [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)

                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>   
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                </mat-form-field>
                                                    
                            </div>
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && !DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag">
                                <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                                    <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [matDatepicker]="picker">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)

                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>   
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                </mat-form-field>
                                                    
                            </div>

                            <!-- fourth div (Image) -->

                            <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==4 &&  !customAttr.isHidden">
                                <div class="mat-form-field">
                                    <div class="mat-form-field-wrapper">
                                        <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                            (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                                        
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                            <span *ngFor="let file of fileArray[customAttr.combinedAttributeName]?.files; index as i">
 
                                                <div class="artist-collection-photo">
                                                    <div class="remove-img-btn" *ngIf="(!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                                    && attributeIdVisiblityMap.get(customAttr.id)==1))"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)" *ngIf="fileArray[customAttr.combinedAttributeName]?.showDeleteOption">
                                                        <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                    </button></div>
                                                    <img width="90" height="100" [src]= "baseUrl + file" alt="image.jpg" class="img-thumbnail" (click)="showImageModalOnly(file)">
                                                    <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div> 
                                                    <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                                </div>

                                            </span>
                                        </span>
                                        <span>
                                            <div *ngIf=" (isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1)) && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="pt-6">No file uploaded</div>
                                        </span>
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                                            <div class="artist-collection-photo" *ngIf="(!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1))">
                                                <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                                <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                                    {{"0"}}/{{customAttr.maxImagesAllowed}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="mat-form-field-underline ng-star-inserted">
                                    <span class="mat-form-field-ripple"></span>
                                </div>
                                <div class="mat-form-field-subscript-wrapper">
                                    <ng-container *ngIf=" isCompleted && !isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError && (fileArray[customAttr.combinedAttributeName].uploaded > 0 && (!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1)))">
                                        <mat-hint class="mat-hint_img"  style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    </ng-container>
                                    <ng-container *ngIf="!isCompleted && !isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                                        <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    </ng-container>

                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                                    <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                                </div>

                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                                    
                            </div>

                            <!-- nineth div (Email) -->
                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==9">
                                <mat-form-field class="mat-form-field-fluid" *ngIf="!customAttr.isHidden">
                                    <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                    <mat-icon  *ngIf="(customAttr.isEdit && !(attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1))" matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                                    <mat-icon  *ngIf="!customAttr.isEdit || (attributeIdVisiblityMap.has(customAttr.id)
                                    && attributeIdVisiblityMap.get(customAttr.id)==1)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                    <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                                </mat-form-field>                                            
                            </div>
                            
                            <!-- tenth div (document upload) -->
                                
                            <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==15 && !customAttr.isHidden">
                                <div class="mat-form-field">
                                    <div class="mat-form-field-wrapper">
                                        <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                            (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                                        
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                            <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName]?.documentIcons; index as i">
            
                                                <div class="artist-collection-photo">
                                                    <div class="remove-img-btn" *ngIf="(!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                                    && attributeIdVisiblityMap.get(customAttr.id)==1))"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)" *ngIf="fileArray[customAttr.combinedAttributeName]?.showDeleteOption">
                                                        <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                    </button></div>
                                                    <a href="{{baseURL + fileArray[customAttr.combinedAttributeName]?.files[i]}}" target="_blank">
                                                        <img width="90" height="100" [src]= "icon" alt="image.jpg" class="img-thumbnail">
                                                    </a>
                                                    <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                                    <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                                </div>
            
                                            </span>
                                            <div *ngIf="(isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1)) && fileArray[customAttr.combinedAttributeName].uploaded==0" class="pt-6">No File Uploaded</div>
                                        </span>
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                                            <div class="artist-collection-photo" *ngIf="(!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1))">
                                                <img width="90" height="100" src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                                <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                                    {{"0"}}/{{customAttr.maxImagesAllowed}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="mat-form-field-underline ng-star-inserted">
                                    <span class="mat-form-field-ripple"></span>
                                </div>
                                <div class="mat-form-field-subscript-wrapper">
                                    <ng-container *ngIf="isCompleted && !isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError && (fileArray[customAttr.combinedAttributeName].uploaded>0 && (!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1)) )">
                                        <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    </ng-container>
                                    <ng-container *ngIf="!isCompleted && !isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                                        <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    </ng-container>
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                                    <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                                </div>
            
                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                                    
                            </div>

                            <!-- eleventh div (video upload) --> 
                            <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==16 && !customAttr.isHidden">
                                <div class="mat-form-field">
                                    <div class="mat-form-field-wrapper">
                                        <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                            (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                                        
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                            <span *ngFor="let file of fileArray[customAttr.combinedAttributeName]?.files; index as i">
            
                                                <div class="artist-collection-photo">
                                                    <div class="remove-img-btn" *ngIf="customAttr.systemAttribute.id!=70 && (!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                                    && attributeIdVisiblityMap.get(customAttr.id)==1))"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)" *ngIf="fileArray[customAttr.combinedAttributeName]?.showDeleteOption">
                                                        <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                    </button></div>
                                                    <a href="{{ (fileArray[customAttr.combinedAttributeName]?.files[i]?.includes('http') || fileArray[customAttr.combinedAttributeName]?.files[i]?.includes('https')) ? fileArray[customAttr.combinedAttributeName]?.files[i] : (baseUrl + fileArray[customAttr.combinedAttributeName]?.files[i]) }}" target="_blank">
                                                        <img  *ngIf="customAttr.systemAttribute.id!=70" width="90" height="100" src="./assets/media/svg/icons/General/file-alt-solid.svg" alt="image.jpg" class="img-thumbnail">
                                                        <span *ngIf="customAttr.systemAttribute.id==70" class="btn btn-transparent-primary btn-sm pr-1"><i class="fas fa-play icon-md"></i></span>
                                                    </a>
                                                    <div *ngIf="customAttr.maxImagesAllowed && customAttr.systemAttribute.id!=70" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                                    <div *ngIf="!customAttr.maxImagesAllowed && customAttr.systemAttribute.id!=70" class="img-index text-center ml-0"> {{i+1}} </div>
                                                </div>
            
                                            </span>
                                                <div *ngIf=" (isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)) && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="pt-6">No File Uploaded</div> 
                                        </span>
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                                            <div class="artist-collection-photo" *ngIf="(!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                            && attributeIdVisiblityMap.get(customAttr.id)==1))">
                                                <img width="90" height="100" src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                                <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                                    {{"0"}}/{{customAttr.maxImagesAllowed}}
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="mat-form-field-underline ng-star-inserted">
                                    <span class="mat-form-field-ripple"></span>
                                </div>
                                <div class="mat-form-field-subscript-wrapper" *ngIf="customAttr.systemAttribute.id!=70">
                                    <ng-container *ngIf="isCompleted && !isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError && (fileArray[customAttr.combinedAttributeName].uploaded > 0 && (!isFieldDisable && !(attributeIdVisiblityMap.has(customAttr.id)
                                        && attributeIdVisiblityMap.get(customAttr.id)==1)))">
                                        <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    </ng-container>
                                    <ng-container *ngIf="!isCompleted && !isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                                        <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    </ng-container>
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                                    <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                                </div>
            
                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                                    
                            </div>

                            <!-- twelfth div (reference document) --> 
                            <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==17 && !customAttr.isHidden">
                                <div class="mat-form-field">
                                    <div class="mat-form-field-wrapper">
                                        
                                        <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                            <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName]?.documentIcons; index as i">
            
                                                <div class="artist-collection-photo">
                                                    <img width="90" height="100" [src]= "icon" alt="image.jpg" class="img-thumbnail" (click)="showRefDocModal(customAttr.combinedAttributeName, i)">
                                                </div>
            
                                            </span>
                                        </span>
                                    </div>
                                </div> 
                                <div class="mat-form-field-subscript-wrapper">
                                    <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                                    <!-- <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" > {{customAttr.instructions}} (Optional) </mat-hint>
                                    <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >Optional </mat-hint>
                                
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                                    <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error> -->     
                                </div>                    
                            </div>

                             <!-- thirteenth div (Data list field) -->
                            <div class="col-md-8" *ngIf="customAttr.attributeType.id==18">
                                <mat-form-field  appearance="standard" class="input-100" *ngIf="!customAttr.isHidden">
                
                                    <mat-select  [formControlName]="customAttr.combinedAttributeName" (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr,'') : ''">
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #dataListFieldFilter ></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let values of customAttr.lookupValues| search : dataListFieldFilter.value : 'lookupValue'" [value]="values.id">
                                            {{values.lookupValue}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)

                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon> 
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one.</mat-error>                      
                    
                                </mat-form-field>                    
                            </div> 

                            <div class="col-md-8" *ngIf="customAttr.attributeType.id==21 ">
                                <mat-form-field class="example-full-width" *ngIf="!customAttr.isHidden">
                                    <input matInput type="datetime-local" 
                                     min="{{customAttr.dateAttributes.minDate}}" max="{{customAttr.dateAttributes.maxDate}}" [formControlName]="customAttr.combinedAttributeName" placeholder="" onkeydown="return false;">
                                     <mat-icon  *ngIf="isFieldDisable||customAttr.systemAttribute.id==67||(attributeIdVisiblityMap.has(customAttr.id)

                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>
                            
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                        cannot be blank.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-8"  *ngIf="customAttr.attributeType.id==22">
                                <mat-form-field class="mat-form-field-fluid" *ngIf="!customAttr.isHidden">
                                    <input *ngIf="customAttr.systemAttribute?.id!=68" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces type="time" step="1" onkeydown="return false;">
                                    <!-- Text type time duration field added for Call Duration, Since It'll be always lock. Temp fix of Call Duration Data -->
                                    <input *ngIf="customAttr.systemAttribute?.id==68" type="text" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName">
                                    <mat-icon  *ngIf="isFieldDisable||customAttr.systemAttribute.id==68||(attributeIdVisiblityMap.has(customAttr.id)

                                    && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                    <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                    
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                    
                                </mat-form-field>                                            
                            </div>

        <!--      =========================system Attributes block================================= -->

                            <ng-template #systemAttrbitues >
                                <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="mat-form-field-fluid input-100">
                                                <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                        {{country.countryName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint>Select country.</mat-hint>
                                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                                
                                
                                                <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="mat-form-field-fluid input-100">
                                                <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                        {{state.stateName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank</mat-error>
            
                                                <mat-hint>Select state.</mat-hint>
                                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                               
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="mat-form-field-fluid input-100">
                                                <mat-select formControlName="cityName">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                        {{city.cityName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank</mat-error>
                        
                                                <mat-hint>Select city.</mat-hint>
                                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                               
                                
                                            </mat-form-field>
                                        </div>                                       
                                    </div>                                                        
                                </div>
                            </ng-template>

                            <!--      =========================system Attributes block================================= -->

                            <ng-template #systemAttrbitues >
                                <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21 ">
                                    <div class="row" *ngIf="!customAttr.isHidden">
                                        <div class="col-md-4">
                                            <mat-form-field class="mat-form-field-fluid input-100">
                                                <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                        {{country.countryName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-hint>Select country.</mat-hint>
                                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>  
                                                  
                                
                                                <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="mat-form-field-fluid input-100">
                                                <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                        {{state.stateName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank</mat-error>
            
                                                <mat-hint>Select state.</mat-hint>
                                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon> 
                                               
                                
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="mat-form-field-fluid input-100">
                                                <mat-select formControlName="cityName">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                        {{city.cityName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank</mat-error>
                        
                                                <mat-hint>Select city.</mat-hint>
                                                <mat-icon  *ngIf="isFieldDisable||(attributeIdVisiblityMap.has(customAttr.id)
                                                && attributeIdVisiblityMap.get(customAttr.id)==1)|| !customAttr.isEdit" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>   
                                               
                                            </mat-form-field>
                                        </div>                                       
                                    </div>                                                        
                                </div>
                            
                            </ng-template>

                            <ng-template #systemAttrbituesM >
                        
                                <div class="col-md-12 table_scroll" *ngIf="customAttr.systemAttribute.id==28">
                                    <div >
                                        <div class="form-group row" formArrayName="skuItems">
                                            <div class="row">
                                                <td>                                            
                                                    <tr>
                                                        <table class="style">
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Quantity</th>
                                                                <th>List Price</th>
                                                                <th>Selling Price</th>
                                                                <th>Discount %</th>
                                                                <th>Tax %</th>
                                                                <th>Total</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                                <td>
                                                                    <mat-form-field class="inputSelect">
                                                                        <mat-select formControlName="sku" required>
                                                                            <!-- <mat-option>
                                                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="skuFilterType" ></ngx-mat-select-search>
                                                                            </mat-option> -->
                                                                            <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                                {{sku.productName}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                        
                                                                    </mat-form-field>
                                                                </td>
                                                                    <td>
                                                                        <mat-form-field  class="inputbox"> 
                                                                            <input matInput formControlName="quantity" required ktStrictNumberOnlyDirective >  
                                                                            <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <mat-form-field class="inputbox"> 
                                                                            <input matInput  formControlName="listPrice"  ktStrictNumberOnlyDirective>  
                                                                            <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <mat-form-field class="inputbox"> 
                                                                            <input matInput  formControlName="sellingPrice" required ktStrictNumberOnlyDirective>  
                                                                            <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <mat-form-field class="inputbox"> 
                                                                            <input matInput  formControlName="discount"  ktStrictNumberOnlyDirective>  
                                                                            <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <mat-form-field class="inputbox"> 
                                                                            <input matInput  formControlName="tax" required ktTwoDigitDecimaNumberDirective>  
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td>
                                                                        <mat-form-field class="inputbox"> 
                                                                            <input matInput  formControlName="total"  ktStrictNumberOnlyDirective>
                                                                            <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                        </mat-form-field>
                                                                    </td>
                                                                    <td style="width:90px;"> 
                                                                        <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                            <i class="fas fa-plus" (click)="addTable()"></i>
                                                                        </button>
                                                    
                                                                        <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="addCompaniesForm.controls.skuItems.controls.length > 1">
                                                                            <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                                        </button>
                                                                    
                                                                    </td>
                                                                    
                                                        </tr>
                                                        
                                                        </table>
                                                    </tr>
                                                    
                                                
                                                <!-- <tr>
                                                        <td>
                                                            <div class="col-md-12">
                                                                <div class="row">
                                                                    <div class="col-md-9">
                                                                        <label class="col-form-label form-padding-top-15"></label>
                                                                    </div> 
                                                                    <div class="col-md-3">
                                                                        <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{totalSum}}</label>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>  -->
                                                </td>
                                            </div>  
                                        
                                        </div>                                   
                                    </div>  
                                    <!-- <mat-error *ngIf="isControlHasError('sku', 'required')">Please select product</mat-error> -->
                                    <mat-error *ngIf="addProductError">Please select product</mat-error>
                                
                                </div>
                            </ng-template>
                                
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <span style="color:red">{{errorMes}}</span>
                            </div>
                            
                        </div>
                    </form> 
                    <form [formGroup]="actionForm" autocomplete="off" *ngIf="!noFieldData && !isActivityPage && !activityFlag && !isFromCompanyDashboard" >
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="col-form-label form-padding-top-25">Action <span class="mandatory-marker">*</span></label>
                            
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="standard" class="input-100">
                                    <mat-label></mat-label>
                                    <mat-select (selectionChange)="getRemarks($event.value)" #select10 formControlName="action" ktAutoTrimSpaces>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #singleSelectFilter></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let action of actionType|search:singleSelectFilter.value:'value'; " [value]="action.key">
                                            {{action.value}}
                                        </mat-option>
                                        </mat-select>
                                        <mat-icon  *ngIf="isCompleted" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon> 
                                        <mat-error *ngIf="isControlHasError('action', 'required')">Action cannot be blank
                                        </mat-error>
                                    </mat-form-field></div>
                            </div>
                        </form>
                        <form [formGroup]="remarksForm" *ngIf="showRemarks" >
                            <div class="form-group row">
                                <div class="col-md-4">
                                    <label class="col-form-label form-padding-top-25">Remarks
                                        <span class="mandatory-marker">*</span>
                                    </label>
                                </div>
                                <div class="col-md-8">
                                    <mat-form-field appearance="standard" class="input-100">
                                        <mat-label></mat-label>
                                        <input matInput formControlName="remarks" type="text"/>
                                        <mat-hint *ngIf="optionalRemarks">Optional</mat-hint>  
                                        <mat-icon  *ngIf="isCompleted" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                                        <mat-error *ngIf="isControlHasError('remarks', 'required')">Remarks Mandatory
                                        </mat-error> 
                                        
                                        </mat-form-field>

                                    </div>
                                </div>
                            </form>
                            
                        <div *ngIf="noFieldData">
                            <h3>You cannot take any action on this Approval.</h3>
                        </div>
                    </div>


            <div class="modal-footer" *ngIf="isSubmitShow">
                <div class="align-self-start mr-auto">
                <button *ngIf="isActivityPage && isDeleteAllowed" type="button" class="btn btn-danger" [disabled]='disabled || this.spinnerCount !== 0' (click)="delete()">Delete</button></div>
                <mat-error *ngIf="editOppVal==undefined"> No Data Found</mat-error>
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button *ngIf="editOppVal && (!isCompleted && !noFieldData && isEditAllowed)" type="button" class="btn btn-primary" [disabled]='disabled || this.spinnerCount !== 0' (click)="Save()">Save</button>
                
               
            </div>
    </div>
</div>  
    

<ngx-spinner class="SpinnerLoading" bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="addCompany">  
    <p class="SpinnerFetch">Fetching Data... </p>  
</ngx-spinner>
