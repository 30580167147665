<!--begin::Body-->
<div class="d-flex flex-column-fluid flex-center mt-10 mt-lg-10 height-100" style="align-items:baseline;">
	<!--begin::Signin-->
	<div class="login-form login-signin">
		<div class="text-center mb-5 mb-lg-10" *ngIf="!validOTP">
			<h3 class="font-size-h1">Verify OTP</h3>
			<p class="text-muted font-weight-semi-bold">Please enter the OTP sent to your registered mobile number below
				to reset your password</p>
		</div>

		<div class="mt-5 mt-lg-10 mb-5 mb-lg-10" *ngIf="validOTP">
			<p>Hello {{name}}!</p>
            <p>Please create your new password and login to your account.</p>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" *ngIf="!validOTP" [formGroup]="validateOtpForm" autocomplete="off">
			<div class="form-group">
				<mat-form-field>
					<mat-label>Enter OTP
						<span style="color: red; display: inline;">*</span>
					</mat-label>
					<input matInput placeholder="" formControlName="otp" autocomplete="off" ktAutoTrimSpaces />
					<mat-error *ngIf="isControlHasError('otp','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('otp', 'pattern')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>

				</mat-form-field>
			</div>
			<!--begin::Action-->
			<div class="form-group text-right">
				<a href="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</a>
				<button (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{
					'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
			</div>
			<a href="javascript:;" (click)="requestOtp()" class="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot"
				style="text-decoration: underline;">Resend OTP</a>
			<!--end::Action-->

		</form>

		<form class="form" *ngIf="validOTP" [formGroup]="registerForm" autocomplete="off">
			<div class="form-group" [ngStyle]="{'padding-bottom': '0px'}">
			  <mat-form-field>
				<mat-label>Username
				  <span style="color: red; display: inline;">*</span>
				</mat-label>
				<input matInput type="text" placeholder="Username" 
				  formControlName="username" [(ngModel)]="userPhone" readonly />
				<mat-error *ngIf="isControlHasError('username', 'required')">
				  <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
				</mat-error>
				<mat-error *ngIf="isControlHasError('username', 'minlength')">
				  <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
				</mat-error>
				<mat-error *ngIf="isControlHasError('username', 'maxlength')">
				  <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
				</mat-error>
			  </mat-form-field>
			</div>
			<div class="form-group" [ngStyle]="{'padding-bottom': '0px'}">
			  <mat-form-field>
				<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}
				  <span style="color: red; display: inline;">*</span>
				</mat-label>
				<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
				  formControlName="password" autocomplete="off" />
				<mat-error *ngIf="isControlHasError('password', 'required')">
				  <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
				</mat-error>
				<mat-error *ngIf="isControlHasError('password', 'minlength')">
				  <strong>Password should be 8 characters or more</strong>
				</mat-error>
				<mat-error *ngIf="isControlHasError('password', 'maxlength')">
				  <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
				</mat-error>
				<mat-error *ngIf="registerForm.get('password').errors && registerForm.get('password').errors.password">
				  <strong>Password can't be same as username.</strong>
				</mat-error>
			  </mat-form-field>
			</div>
			<div class="form-group" [ngStyle]="{'padding-bottom': '0px'}">
			  <mat-form-field>
				<mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}
				  <span style="color: red; display: inline;">*</span>
				</mat-label>
				<input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
				  formControlName="confirmPassword" autocomplete="off" />
				<mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
				  <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
				</mat-error>
				<mat-error *ngIf="isControlHasError('confirmPassword', 'minlength')">
				  <strong>Password should be 8 characters or more</strong>
				</mat-error>
				<mat-error *ngIf="isControlHasError('confirmPassword', 'maxlength')">
				  <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
				</mat-error>
				<mat-error
				  *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
				  <strong>Passwords do not match.</strong>
				</mat-error>
			  </mat-form-field>
			</div>
			<!-- Actions -->
			<div class="form-group text-right">
			  <a href="/auth/login" id="kt_login_signup_cancel"
				class="btn btn-secondary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</a>
			  <button (click)="submitForResetPwd()" id="kt_login_signup_submit"
				class="btn btn-primary font-weight-bold px-9 py-4 my-3"
				[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
			</div>
		  </form>
	</div>
</div>