<!-- begin:: Footer -->
<div class="footer bg-white py-4 d-flex flex-lg-column " id="kt_footer" [ngClass]="footerClasses">
	<div [ngClass]="footerContainerClasses" class=" d-flex flex-column flex-md-row align-items-center justify-content-between">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
			<span class="text-muted font-weight-bold mr-2">{{today | date:'yyyy'}} &copy;</span>
      <a href="https://www.getassistive.com/" target="_blank" class="text-dark-75 text-hover-primary">Channelplay SaaS Pvt Ltd</a>
		</div>
    <!--end::Copyright-->

    <!--begin::Nav-->
    <div class="nav nav-dark order-1 order-md-2">
			<img *ngIf="isAppIntegerationEnable" src="./assets/media/svg/icons/Communication/dialpad.svg" class="mr-20 cursor-pointer" (click) = "openDialsearch()">
			<div class="display-none">
				<a href="https://www.channelplay.in/india/about-us" target="_blank" class="nav-link pr-3 pl-0">About</a>
				<a href="https://www.channelplay.in/india/our-leaders" target="_blank" class="nav-link px-3">Team</a>
				<a href="https://www.channelplay.in/business-enquiries" target="_blank" class="nav-link pl-3 pr-0">Contact</a>
			</div>
		</div>
	</div>
</div>
<!-- end:: Footer -->
