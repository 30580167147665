// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule} from '@angular/material/dialog'

// Translate
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
// Module components
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthNoticeComponent } from './auth-notice/auth-notice.component';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { AuthenticateTokenComponent } from './authenticate-token/authenticate-token.component';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
// Auth
import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';

import { SharedModule } from '../shared/shared.module';
import { AppReportsComponent } from './app-reports/app-reports.component';
import { ConfirmLoginComponent } from './login/subs/confirm-login/confirm-login.component';
import { AuthenticateOtpComponent } from './authenticate-otp/authenticate-otp.component';


const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'register',
				component: RegisterComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent
			},
			{
				path: 'authenticate-otp',
				component: AuthenticateOtpComponent
			},
			{
				path: 'authenticate-token/:token',
				component: AuthenticateTokenComponent
			},
			{
				path: 'resetpassword-token/:token',
				component: AuthenticateTokenComponent
			},
			{
				path: 'report-power-bi/:id/:token',
				component: AppReportsComponent
			  }
		]
	}
];


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		MatDialogModule,
		SharedModule
	],
	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
	],
	entryComponents: [
		AuthDialogComponent,
		ProjectSelectionComponent,
		ConfirmLoginComponent
	],
	exports: [AuthComponent],
	declarations: [
		AuthComponent,
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		AuthNoticeComponent,
		AuthDialogComponent,
		AuthenticateTokenComponent,
		AuthenticateOtpComponent,
		ProjectSelectionComponent,
		AppReportsComponent,
		ConfirmLoginComponent
	]
})

export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard
			]
		};
	}
}
