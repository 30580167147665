<div class="d-flex flex-column-fluid flex-center mt-5">
  <!--begin::Signin-->
  <div class="login-form login-signin">
    <div class="text-center mb-5 mb-lg-10">
      <h3 class="font-size-h1">Sign In</h3>
      <p class="text-muted font-weight-bold">
        Enter your username and password
      </p>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <!--begin::Form-->
    <form class="form" [formGroup]="loginForm" autocomplete="off" novalidate="novalidate" id="kt_login_form">
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.EMAIL" | translate }}
            <span style="color: red; display: inline;">*</span>
          </mat-label>
          <input matInput type="email" placeholder="" formControlName="email"
            autocomplete="off" ktAutoTrimSpaces/>
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ "AUTH.VALIDATION.INVALID_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minlength')">
            <strong>{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxlength')">
            <strong>{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}
            <span style="color: red; display: inline;">*</span>
          </mat-label>
          <input matInput type="password" placeholder=""
            formControlName="password" autocomplete="off" />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong>{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong>{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!--begin::Action-->
      <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
        <a href="javascript:;"  (click)="openForgotPasswordDialog(4,-1)" class="text-dark-50 text-hover-primary my-3 mr-2"
          id="kt_login_forgot">{{ "AUTH.GENERAL.FORGOT_BUTTON" | translate }}</a>
        <button id="kt_login_signin_submit" (click)="submit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold px-9 py-4 my-3">{{ "AUTH.LOGIN.BUTTON" | translate }}</button>
      </div>
      <!--end::Action-->
    </form>
    <!--end::Form-->
    <!--
    <div class="mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5">
          <span class="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <a
            href="javascript:;"
            routerLink="/auth/register"
            class="font-weight-bold ml-2"
            id="kt_login_signup"
          >{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a>
    </div>
    -->
  </div>
</div>
 <!--end::Signin-->
  